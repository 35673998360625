import React from "react";
import blog2Data from "data/blog2.json";
import DarkTheme from "layouts/Dark";
import Navbar from "components/Navbar/navbar";
import BlogListed from "components/Blog-list/blog-list";
import PageHeader from "components/Page-header/page-header";
import Footer from "components/Footer/footer";


const BlogListDark = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
      <Navbar nr={navbarRef} lr={logoRef} />
      <PageHeader
        className="sub-bg"
        title="Our Media"
        paragraph="Current news and events of our creative team."
      />
      <h1 className="d-none">Current news and events of our creative team.</h1>
      <BlogListed blogs={blog2Data} />
      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Media Coverage - Elfonze Technologies</title>
      <meta key='description' name="description" 
      title="Inside Elfonze Media Coverage: Visionary Growth, Innovation & Industry Praise"
        content="Explore the latest media highlights featuring Elfonze’s design excellence, technological innovation, and global expansions. Uncover how the brand’s forward-thinking approach is capturing the attention of top industry publications"
      />
    </>
  )
}

export default BlogListDark ;
